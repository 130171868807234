import {
  Box,
  Button,
  Typography,
  makeStyles,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Checkbox,
  LinearProgress,
} from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import { useIsMounted } from "hooks/useIsMounted";
import { getEstudiantesParaAnotacionesSinTarea, guardarAnotacionesSinTarea } from "repositories/estudiante_repository";
import message from "plugins/utils/message";
import { I18n, Translate } from "react-redux-i18n";

type TableModel = {
  estudianteId: string;
  estudianteNombre: string;
  valorAnotacion: boolean;
  tienePermiso: boolean;
  tienePermisoParcial: boolean;
};

export type Anotacion = {
  id: string;
};

export type EstudianteAnotacionST = {
  id: string;
  codigoRude: string;
  fechaNacimiento: string;
  foto: string;
  cursoId: string;
  usuarioId: string;
  nombreCompleto: string;
  estado: string;
  usuario: {
    email: string;
    rol: string;
    nombres: string;
    primerApellido: string;
    segundoApellido: string;
    nombreCorto: string;
    direccion: string;
    celular: string;
    ci: string;
    ciudad: string;
    estado: string;
    idioma: string;
  };
  anotacion?: Anotacion | null;
  tienePermiso: boolean;
  tienePermisoParcial: boolean;
  // estudiante: {
  //   id: string;
  //   nombre: string;
  //   foto: string;
  // };
  // asistencia: {
  //   id: string;
  //   valor: string;
  // } | null;
  // permiso: {
  //   id: string;
  //   fechaInicio: string;
  //   fechaFin: string;
  //   motivo: string;
  // } | null;
};

async function getEstudiantes(cursoId: string, claseId: string) {
  const estudiantes: EstudianteAnotacionST[] = await getEstudiantesParaAnotacionesSinTarea(cursoId, claseId);
  if (!estudiantes) return { rows: [] };

  const rows: TableModel[] = estudiantes.map(est => {
    return {
      estudianteId: est.id,
      estudianteNombre: est.nombreCompleto,
      valorAnotacion: !!est.anotacion,
      tienePermiso: !!est.tienePermiso,
      tienePermisoParcial: !!est.tienePermisoParcial,
    };
  });

  return { rows };
}

type StepProps = {
  cursoId: string;
  claseId: string;
  materiaId: string;
  onGuardarClick: () => void;
};

const StepAnotacionSinTarea = ({ cursoId, claseId, materiaId, onGuardarClick }: StepProps): ReactElement => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableModel[]>([]);
  const [presenteChecks, setPresenteChecks] = React.useState<{ [key: string]: boolean }>({});
  const isMounted = useIsMounted();

  const cargarEstudiantes = async () => {
    setLoading(true);
    const result = await getEstudiantes(cursoId, claseId);
    if (isMounted()) setLoading(false);
    if(!result) return [];
    setRows(result.rows);
    const newPresenteChecks: any = {};
    result.rows.forEach(r => {
      newPresenteChecks[r.estudianteId] = r.valorAnotacion;
    });
    setPresenteChecks(newPresenteChecks);
  };
  const cargadoInicial = async () => {
    cargarEstudiantes();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleGuardarClick = async () => {
    setLoading(true);
    const anotaciones = rows.map(row => {
      return {
        categoria: "Sin Tarea",
        estudianteId: row.estudianteId,
        descripcion: "El estudiante no entregó la tarea",
        valor: !!presenteChecks[row.estudianteId],
      };
    });
    const data: any = {
        anotaciones,
    };
    await guardarAnotacionesSinTarea(cursoId, claseId, data);
    setLoading(false);
    message.success(I18n.t('anotacionesGuardadasConExito'));
    onGuardarClick();
  };

  const handlePresenteChange = (row: TableModel, checked: boolean) => {
    setPresenteChecks({ ...presenteChecks, [row.estudianteId]: checked });
  };

  return (
    <>
      {loading && <Box position="absolute" left="0" right="0">
        <LinearProgress variant="indeterminate" color="secondary"/>
      </Box>}
      <Box className={classes.root}>
        <Box mb={2}>
          <Typography><Translate value="seleccioneEstudiantesSinTarea" /></Typography>
        </Box>
        <Box position="relative">
          <TableContainer component={Paper} elevation={0} style={{ border: 'solid lightgrey 1px' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong><Translate value="estudiante" /></strong></TableCell>
                  <TableCell></TableCell>
                  <TableCell><strong><Translate value="noPresentado" /></strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && <TableRow><TableCell colSpan={999}><Typography align="center">Cargando...</Typography></TableCell></TableRow>}
                {!loading && rows.length === 0 &&<TableRow><TableCell colSpan={999}><Typography align="center">Sin registros</Typography></TableCell></TableRow>}
                {rows.map((row) => (
                  <TableRow key={row.estudianteId}>
                    <TableCell>{row.estudianteNombre}</TableCell>
                    <TableCell>
                      {row.tienePermiso && (
                      <Typography style={{ color: '#99BA82' }}>
                        {row.tienePermisoParcial && <Translate value="tienePermisoParcial" />}
                        {!row.tienePermisoParcial && <Translate value="tienePermiso" />}
                      </Typography>)}
                    </TableCell>
                    <TableCell>
                      {typeof presenteChecks[row.estudianteId] !== 'undefined' &&<Checkbox
                          style ={{ color: row.tienePermiso ? undefined : "#FAE268" }}
                          checked={presenteChecks[row.estudianteId]}
                          disabled={row.tienePermiso}
                          onChange={(event) => handlePresenteChange(row, event.target.checked)}
                        />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" alignContent="center" justifyContent="center">
          <Button disabled={loading} type="button" onClick={handleGuardarClick} className={classes.btnSaveForm} fullWidth>
            <Typography variant="body2" noWrap><Translate value="form.guardar" /></Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
  btnSaveForm: {
    margin: theme.spacing(4, 'auto'),
    maxWidth: '150px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  table: {
    minWidth: 650,
  },
}));

export default StepAnotacionSinTarea;
