import { FiltroCargarEstudiantes } from '../store/actions/estudiante_foto_actions';
import http from './http';
import { IStudentPhoto } from '../models/estudiante_foto_model';
import message from '../plugins/utils/message';

export const getStudentPhotosService = async (
  tab: 'recibidos' | 'aprobados' | 'rechazados',
  filtersParams?: FiltroCargarEstudiantes,
) => {
  try {
    let filters = [];
    if (filtersParams?.codigoEstudiante) {
      filters.push(`codigoEstudiante=${filtersParams.codigoEstudiante}`);
    }
    if (filtersParams?.curso) {
      filters.push(`curso=${filtersParams.curso}`);
    }
    if (filtersParams?.idEstudiante) {
      filters.push(`idEstudiante=${filtersParams.idEstudiante}`);
    }
    if (filtersParams?.gestion) {
      filters.push(`gestion=${filtersParams.gestion}`);
    }
    if (filtersParams?.fechaDesde) {
      filters.push(`fechaDesde=${filtersParams.fechaDesde}`);
    }
    if (filtersParams?.fechaHasta) {
      filters.push(`fechaHasta=${filtersParams.fechaHasta}`);
    }

    if (tab === 'recibidos') {
      filters.push(`estado=PENDIENTE`);
    } else if (tab === 'rechazados') {
      filters.push(`estado=OBSERVADO`);
    }
    else {
      filters.push(`estado=ACTIVO`);
    }

    const items = await http.get(
      `/fotos-estudiantes/${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return items as {
      rows: IStudentPhoto[];
      count: number;
    };
  } catch (error) {
    console.error('[get_reglamentos]  Error -> ', error);
    return {
      rows: [],
      count: 0,
    };
  }
};

export const rejectStudentPhotoService = async (
  idSolicitud: string,
  idStudent: string,
  motivo: string,
) => {
  try {
    return await http.put(`/fotos-estudiantes/${idSolicitud}/rechazar`, {
      motivo,
      idStudent,
    });
  } catch (error: any) {
    console.log('Error en rejectStudentPhotoService', error);
    message.error(error?.message || 'Error al rechazar la foto');
    return null;
  }
};

export const approveStudentPhotoService = async (
  idSolicitud: string,
  idStudent: string,
  croppedImage: File,
) => {
  try {
    const formData = new FormData();
    formData.append('idStudent', idStudent);
    if (croppedImage) {
      formData.append('file', croppedImage);
    }
    return await http.put(
      `/fotos-estudiantes/${idSolicitud}/aprobar`,
      formData,
      {
        'Content-Type': 'multipart/form-data',
      },
    );
  } catch (error: any) {
    console.log('Error en approveStudentPhotoService', error);
    message.error(error?.message || 'Error al aprobar la foto');
    return null;
  }
};

export const downloadPhotoStudent = async (
  idSolicitud: string,
  fileName: string,
) => {
  try {
    const response = await http.download(
      `/fotos-estudiante/solicitud/${idSolicitud}/download`,
      fileName,
      // fileName.split('.').pop().toLowerCase() || 'JPG',
    );
    return response;
  } catch (error) {
    console.error('Error al descargar el archivo del reglamento :: ', error);
    return null;
  }
};

export const downloadPhotosStudentLote = async (
  tab: 'recibidos' | 'aprobados',
  filtersParams?: FiltroCargarEstudiantes,
) => {
  try {
    let filters = [];
    if (filtersParams?.codigoEstudiante) {
      filters.push(`codigoEstudiante=${filtersParams.codigoEstudiante}`);
    }
    if (filtersParams?.curso) {
      filters.push(`curso=${filtersParams.curso}`);
    }
    if (filtersParams?.idEstudiante) {
      filters.push(`idEstudiante=${filtersParams.idEstudiante}`);
    }
    if (filtersParams?.gestion) {
      filters.push(`gestion=${filtersParams.gestion}`);
    }
    if (filtersParams?.fechaDesde) {
      filters.push(`fechaDesde=${filtersParams.fechaDesde}`);
    }
    if (filtersParams?.fechaHasta) {
      filters.push(`fechaHasta=${filtersParams.fechaHasta}`);
    }

    if (tab === 'recibidos') {
      filters.push(`estado=PENDIENTE`);
    } else {
      filters.push(`estado=ACTIVO`);
    }

    return await http.download(
      `/fotos-estudiantes/lote${filters.length ? '?' + filters.join('&') : ''}`,
      'student-photos.zip',
    );
  } catch (error) {
    console.error('[get_reglamentos]  Error -> ', error);
    return {
      rows: [],
      count: 0,
    };
  }
};
