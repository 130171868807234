import React from 'react';

const Util = {
  replace(text: string, find: any, replace: any) {
    for (let i = 0, l = find.length, regex; i < l; i++) {
      regex = new RegExp(find[i], 'g');
      text = text.replace(regex, replace[i]);
    }
    return text;
  },

  buildDisplayName(record: any): string {
    record = record || {};
    const A = record.nombres || '';
    const B = record.primerApellido || '';
    const C = record.segundoApellido || '';
    const displayName = `${`${A} ${B}`.trim()} ${C}`.trim();
    return displayName;
  },

  getYearsList(desde?: number) {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= (desde || 2022); i--) {
      years.push(i);
    }
    return years;
  },

  truncateFileName(fileName: string, maxLength: number = 30) {
    if (fileName.length <= maxLength) return fileName;
    // Extraer la extensión (si existe)
    const extensionIndex = fileName.lastIndexOf('.');
    const hasExtension = extensionIndex >= 0;
    const name = hasExtension ? fileName.slice(0, extensionIndex) : fileName;
    const extension = hasExtension ? fileName.slice(extensionIndex) : '';
    // Cortar solo el exceso al final del nombre (antes de la extensión)
    const truncatedName = name.slice(0, maxLength - extension.length);
    return `${truncatedName}…${extension}`;
  },
};

export default Util;

// Hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}
