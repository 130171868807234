import {
  approveStudentPhotoService,
  getStudentPhotosService,
  rejectStudentPhotoService,
} from '../../repositories/estudiante_foto_repository';
import { STUDENTS_PHOTOS_TYPES } from '../types';
import { IStudentPhoto } from '../../models/estudiante_foto_model';

export interface FiltroCargarEstudiantes {
  idEstudiante?: string;
  curso?: string;
  gestion?: string;
  codigoEstudiante?: string;
  fechaDesde?: string;
  fechaHasta?: string;
}

export const getStudentPhotos = (
  bandeja: 'recibidos' | 'aprobados' | 'rechazados',
  filtros?: FiltroCargarEstudiantes,
) => {
  return async (dispatch: any) => {
    const { rows } = await getStudentPhotosService(bandeja, filtros);
    dispatch(setListStudentPhotos(rows || []));
  };
};

export const rejectStudentPhoto = (data: {
  idStudent: string;
  motivoRejection: string;
  idSolicitud: string;
}) => {
  return async () => {
    const response = await rejectStudentPhotoService(
      data.idSolicitud,
      data.idStudent,
      data.motivoRejection,
    );
    return response;
  };
};

export const approveStudenPhoto = (data: {
  idStudent: string;
  idSolicitud: string;
  croppedImage: any;
}) => {
  return async () => {
    const response = await approveStudentPhotoService(
      data.idSolicitud,
      data.idStudent,
      data.croppedImage,
    );
    return response;
  };
};

export const setListStudentPhotos = (listStudentPhotos: IStudentPhoto[]) => {
  return {
    type: STUDENTS_PHOTOS_TYPES.LOAD_PHOTOS,
    payload: [...listStudentPhotos],
  };
};
