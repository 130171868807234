import {
  Box,
  Button,
  Chip,
  createStyles,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import MainLayout from 'components/layout/MainLayout';
import { ITicket } from 'models/ticket_model';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';
import {
  cargarTicketsMonitor,
  cargarTicketsProfesor,
} from 'store/actions/ticket';
import { RootState, useStateSelector } from 'store/reducers';

const ColorsEstado = {
  Abierto: 'rgb(99,148,223)',
  Pendiente: 'rgb(250,226,104)',
  Resuelto: 'rgb(76,185,80)',
  Cerrado: 'rgb(229,136,123)',
};

const EstadoTicketEnum = {
  Abierto: 'Abierto',
  Pendiente: 'Pendiente',
  Resuelto: 'Resuelto',
  Cerrado: 'Cerrado',
};

interface Column {
  id:
    | 'tipoProblema'
    | 'contenido'
    | 'estado'
    | 'usuarioSolicitud'
    | 'fechaCreacion'
    | 'fechaModificacion';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const Ticket: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, setUser] = useState<any>({});

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [loading, setLoading] = React.useState<boolean>(false);
  const { tickets } = useStateSelector((state: RootState) => state.ticketState);

  const { locale } = useStateSelector((state: RootState) => state.i18n);

  const toHome = () => {
    history.push('/');
  };

  const [columns, setColumns] = useState<Column[]>([
    {
      id: 'tipoProblema',
      label: I18n.t('tabla.tipoProblema'),
    },
    {
      id: 'contenido',
      label: I18n.t('descripcionUbicacion'),
    },
    {
      id: 'fechaCreacion',
      label: I18n.t('tabla.fechaPublicacion'),
    },
    {
      id: 'fechaModificacion',
      label: I18n.t('tabla.fechaActualizacion'),
    },
    {
      id: 'estado',
      label: I18n.t('estado'),
    },
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRefreshTickets = () => {
    setLoading(true);
    if (user.rol === 'PROFESOR') {
      dispatch(cargarTicketsProfesor());
    } else {
      dispatch(cargarTicketsMonitor());
    }
    setLoading(false);
  };

  const initialData = async () => {
    setLoading(true);
    if (user.rol === 'PROFESOR') {
      await dispatch(cargarTicketsProfesor());
    } else {
      await dispatch(cargarTicketsMonitor());
    }
    setTimeout(() => setLoading(false), 200);
  };

  useEffect(() => {
    const userLocal = window.localStorage.getItem('user');
    if (userLocal) {
      setUser(JSON.parse(userLocal));
      if (JSON.parse(userLocal)?.rol === 'MONITOR') {
        const existColumn = columns.find(
          (column) => column.id === 'usuarioSolicitud',
        );
        if (existColumn) return;

        const newColumns: Column[] = [
          ...columns.slice(0, 2),
          {
            id: 'usuarioSolicitud',
            label: I18n.t('tabla.nombre'),
          },
          ...columns.slice(2),
        ];
        setColumns(newColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (!user.rol) return;
    initialData().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user, locale]);

  return (
    <MainLayout>
      <Box mb={2} display="flex" alignItems="center">
        <IconButton onClick={toHome}>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z"
              fill={theme.palette.error.main}
            />
          </svg>
        </IconButton>
        <Typography variant="h6">
          <Translate value="modulo.tickets" />
        </Typography>
      </Box>

      <Paper className={classes.root}>
        <Box p={3} mb={2} display="flex">
          <Button
            variant="contained"
            onClick={handleRefreshTickets}
            style={{ marginLeft: 10 }}
            startIcon={<Refresh />}
          >
            {isDesktop && <Translate value="tabla.refrescar" />}
          </Button>
        </Box>

        {(tickets.length === 0 || tickets === undefined || tickets === null) &&
          !loading && (
            <Typography variant="subtitle1" align="center">
              <Translate value="sinDatosTickets" />
            </Typography>
          )}

        {loading && <LinearProgress />}

        {tickets.length > 0 && (
          <>
            <React.Fragment>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: 'bold',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tickets
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row: ITicket) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell align="left">
                              {row.tipoProblema}
                              {user?.rol === 'MONITOR' &&
                                row.tags.map((tag) => (
                                  <Chip
                                    key={tag}
                                    label={tag}
                                    style={{
                                      backgroundColor:
                                        tag === 'IT'
                                          ? 'rgb(234,195,173)'
                                          : 'rgb(223,184,241)',
                                      marginLeft: 5,
                                    }}
                                  />
                                ))}
                            </TableCell>
                            <TableCell align="left">
                              {row.descripcion}
                            </TableCell>
                            {user.rol === 'MONITOR' && (
                              <TableCell align="left">
                                {row.usuarioSolicitud.nombre}
                              </TableCell>
                            )}
                            <TableCell align="left">
                              {row.fechaCreacion}
                            </TableCell>
                            <TableCell align="left">
                              {row.fechaModificacion}
                            </TableCell>
                            <TableCell align="left">
                              <Chip
                                label={row.estado}
                                style={{
                                  backgroundColor:
                                    ColorsEstado[
                                      row.estado as keyof typeof EstadoTicketEnum
                                    ] ?? 'none',
                                  color: '#fff',
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 50, 100, 250]}
                component="div"
                count={tickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${I18n.t('tabla.de')} ${
                    count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`
                  }`
                }
              />
            </React.Fragment>
          </>
        )}
      </Paper>
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 'calc(100vh - 110px)',
      '&::-webkit-scrollbar': {
        width: '3px',
      },
    },
  }),
);

export default Ticket;
