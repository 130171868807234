import MainLayout from '../../components/layout/MainLayout';
import { useHistory } from 'react-router-dom';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import clsx from 'clsx';
import {
  approveStudenPhoto,
  FiltroCargarEstudiantes,
  getStudentPhotos,
  rejectStudentPhoto,
} from '../../store/actions/estudiante_foto_actions';
import { useDispatch } from 'react-redux';
import { RootState, useStateSelector } from '../../store/reducers';
import { IStudentPhoto } from '../../models/estudiante_foto_model';
import ModalReject from './components/modal-reject';
import ModalApprove from './components/modal-approve';

export enum TableEnumStudent {
  TAB_RECIBIDOS = 0,
  TAB_APROBADOS = 1,
  TAB_RECHAZADOS = 2,
}

const StudentPhotos: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState<number>(TableEnumStudent.TAB_RECIBIDOS);
  const [loading, setLoading] = useState<boolean>(false);

  const [modalReject, setModalReject] = useState<boolean>(false);
  const [modalApprove, setModalApprove] = useState<boolean>(false);

  const [itemSelected, setItemSelected] = useState<IStudentPhoto | null>(null);

  const dispatch = useDispatch();
  const { studentPhotos } = useStateSelector(
    (state: RootState) => state.estudianteFotoState,
  );

  const toHome = useCallback(() => {
    if (history) {
      history.goBack();
    }
  }, [history]);

  const handleTabChange = useCallback((tab: number) => {
    setLoading(true);
    setTabSelected(tab);
  }, []);

  const handleApproveOrReject = useCallback(
    async (item: IStudentPhoto, action: 'Aprobado' | 'Rechazado') => {
      setItemSelected(item);
      if (action === 'Aprobado') {
        setModalApprove(true);
        return;
      }
      if (action === 'Rechazado') {
        setModalReject(true);
      }
    },
    [],
  );

  const handleChangeSolicitud = useCallback(
    async (
      action: 'reject' | 'approve',
      data: {
        motivoRejection?: string;
        idStudent: string;
        idSolicitud: string;
        croppedImage?: any;
      },
    ) => {
      setLoading(true);
      if (action === 'reject') {
        if (!data.motivoRejection) {
          setLoading(false);
          return;
        }
        const response = await dispatch(
          rejectStudentPhoto({
            idStudent: data.idStudent,
            idSolicitud: data.idSolicitud,
            motivoRejection: data.motivoRejection || '',
          }),
        );
        if (!response) {
          setLoading(false);
          return;
        }
        setModalReject(false);
      }

      if (action === 'approve') {
        const response = await dispatch(
          approveStudenPhoto({
            idStudent: data.idStudent,
            idSolicitud: data.idSolicitud,
            croppedImage: data.croppedImage,
          }),
        );
        if (!response) {
          setLoading(false);
          return;
        }
        setModalApprove(false);
      }

      setLoading(false);
      loadData().finally();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const TableStudentPhotosComponent = useMemo(
    () => React.lazy(() => import('./components/table-student-photos')),
    [],
  );

  const loadData = useCallback(
    async (filters?: FiltroCargarEstudiantes) => {
      setLoading(true);
      await dispatch(
        getStudentPhotos(
          tabSelected === TableEnumStudent.TAB_APROBADOS ? 'aprobados' : tabSelected === TableEnumStudent.TAB_RECIBIDOS ? 'recibidos' : 'rechazados',
          filters,
        ),
      );
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
    [dispatch, tabSelected],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <MainLayout>
        <Box mb={2} display="flex" alignItems="center">
          <IconButton onClick={toHome}>
            <svg
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z"
                fill="currentColor"
              />
            </svg>
          </IconButton>
          <Typography variant="h6">
            <Translate value="modulo.fotosEstudiantes" />
          </Typography>
        </Box>
        <Grid container spacing={3} style={{ marginBottom: 10 }}>
          <TabButtons
            tabSelected={tabSelected}
            onTabChange={handleTabChange}
            classes={classes}
          />
        </Grid>
        <Box style={{ paddingBottom: 15 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <TableStudentPhotosComponent
              studentsPhotoData={studentPhotos || []}
              handleRefreshReglamentos={loadData}
              handleSubmitFilter={loadData}
              handleFilterCancel={() => {
                loadData().finally();
              }}
              tabSelected={tabSelected}
              loading={loading}
              handleAccionItem={handleApproveOrReject}
            />
          </Suspense>
        </Box>
      </MainLayout>

      {modalReject && itemSelected && (
        <ModalReject
          handleSave={handleChangeSolicitud}
          loading={loading}
          student={itemSelected}
          isModalOpen={modalReject}
          handleClose={() => {
            setModalReject(false);
            setItemSelected(null);
          }}
        />
      )}

      {modalApprove && itemSelected && (
        <ModalApprove
          student={itemSelected}
          loading={loading}
          isModalOpen={modalApprove}
          handleClose={() => {
            setModalApprove(false);
            setItemSelected(null);
          }}
          handleSave={handleChangeSolicitud}
        />
      )}
    </>
  );
};

const TabButtons: React.FC<{
  tabSelected: number;
  onTabChange: (tab: number) => void;
  classes: any;
}> = ({ tabSelected, onTabChange, classes }) => {
  return (
    <div className={classes.box}>
      <Box>
        <button
          // type="button"
          onClick={() => {
            onTabChange(TableEnumStudent.TAB_RECIBIDOS);
          }}
          style={{
            minWidth: '150px',
          }}
          className={clsx(classes.boxItem, {
            [classes.boxItemSelected]: tabSelected === TableEnumStudent.TAB_RECIBIDOS,
          })}
        >
          <Translate value="studentPhotos.tab_recibidos" />
        </button>
      </Box>

      <Box>
        <button
          // type="button"
          onClick={() => {
            onTabChange(TableEnumStudent.TAB_APROBADOS);
          }}
          style={{
            minWidth: '150px',
          }}
          className={clsx(classes.boxItem, {
            [classes.boxItemSelected]: tabSelected === TableEnumStudent.TAB_APROBADOS,
          })}
        >
          <Translate value="studentPhotos.tab_aprobadas" />
        </button>
      </Box>

      <Box>
        <button
          // type="button"
          onClick={() => {
            onTabChange(TableEnumStudent.TAB_RECHAZADOS);
          }}
          style={{
            minWidth: '150px',
          }}
          className={clsx(classes.boxItem, {
            [classes.boxItemSelected]: tabSelected === TableEnumStudent.TAB_RECHAZADOS,
          })}
        >
          <Translate value="studentPhotos.tab_rechazados" />
        </button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      background: '#FFFFFF',
      border: '1px solid #FAE268',
      boxSizing: 'border-box',
      borderRadius: '6px',
      marginTop: '15px',
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: '1px solid #AAAAAA',
    },
    boxItem: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      borderBottom: '1px solid #AAAAAA',
      borderLeft: '1px solid #AAAAAA',
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxItemSelected: {
      fontWeight: 'bold',
      backgroundColor: '#fff7b080',
    },
  }),
);

export default StudentPhotos;
