import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

// Views
import HomePage from '../views/Home';
import UserPage from '../views/User';
import CursoDetallePage from '../views/Curso/components/CursoDetalle';
import CursoTab from '../views/CursoTab';
import Calendar from '../views/Calendar';
import Permission from '../views/Permission';
import Report from 'views/Report';
import Kardex from 'views/Kardex';
import Notifications from 'views/Notifications';
import CourseBook from 'views/LibroDeCurso';
import KinderAttendance from 'views/AsistenciaKinder';
import Help from 'views/Ayuda';
import Circulares from 'views/Circulares';
import Ticket from 'views/Ticket';
import Certificates from 'views/Certificates';
import StudentPhotos from '../views/StudentPhotos';

const AccountRouter = () => {
  return (
    <Router>
        <Switch>
          <Route path='/home' exact>
            <HomePage />
          </Route>
          <Route path='/users' exact>
            <UserPage />
          </Route>
          <Route path='/courses' exact>
            <CursoTab />
          </Route>
          <Route path='/courses/:cursoId' exact>
            <CursoDetallePage />
          </Route>
          <Route path='/calendar' exact>
            <Calendar />
          </Route>
          <Route path='/permission' exact>
            <Permission />
          </Route>
          <Route path='/kardex' exact>
            <Kardex />
          </Route>
          <Route path='/reports' exact>
            <Report />
          </Route>
          <Route path='/notifications' exact>
            <Notifications />
          </Route>
          <Route path='/course-book' exact>
            <CourseBook />
          </Route>
          <Route path='/kinder-asistencia' exact>
            <KinderAttendance />
          </Route>
          <Route path='/help' exact>
            <Help />
          </Route>
          <Route path='/circulares' exact>
            <Circulares />
          </Route>
          <Route path='/ticket' exact>
            <Ticket />
          </Route>
          <Route path='/certificates' exact>
            <Certificates />
          </Route>
          <Route path='/student-photos' exact>
            <StudentPhotos />
          </Route>
          <Redirect to='/home' />
        </Switch>
    </Router>
  )
}

export default AccountRouter
