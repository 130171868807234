import {
  ICertificate,
  IUSuariosVerificadosCertificado,
} from 'models/certificate_model';
import { Action, CERTIFICATE_TYPES } from 'store/types';

export type State = {
  userVerification: IUSuariosVerificadosCertificado[];
  plantillas: {
    id: string;
    nombre: string;
    cantidadCaracteres: number;
    fraseInicial: string;
    fraseInicialDe: string | null;
    cantidadCaracteresTematicas: number | null;
  }[];
  certificates: ICertificate[] | [];
  countCertificates: number;
};

const initialState: State = {
  userVerification: [],
  plantillas: [],
  certificates: [],
  countCertificates: 0,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case CERTIFICATE_TYPES.VERIFICATE_USER_CERTIFICATES:
      return {
        ...state,
        userVerification: payload,
      };
    case CERTIFICATE_TYPES.LOAD_PLANTILLAS_CERTIFICATES:
      return {
        ...state,
        plantillas: payload,
      };
    case CERTIFICATE_TYPES.LOAD_CERTIFICATES:
      return {
        ...state,
        certificates: payload,
      };
    case CERTIFICATE_TYPES.LOAD_CERTIFICATES_COUNT:
      return {
        ...state,
        countCertificates: payload,
      };
    default:
      return state;
  }
};

export default reducer;
