import React, { Suspense, useState, useCallback } from 'react';
import MainLayout from '../../components/layout/MainLayout';
import {
  Box,
  IconButton,
  Typography,
  Grid,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import clsx from 'clsx';

const TAB_CIRCULARES = 1;
const TAB_REGLAMENTOS = 2;

const Circulares: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState<number>(TAB_CIRCULARES);

  const PageCircularesComponentes = React.lazy(
    () => import('./ui/page-circulares'),
  );
  const PageReglamentosComponente = React.lazy(
    () => import('./ui/page-reglamentos'),
  );

  const toHome = useCallback(() => {
    if (history) {
      history.push('/');
    }
  }, [history]);

  const handleTabChange = useCallback((tab: number) => {
    setTabSelected(tab);
  }, []);

  return (
    <MainLayout>
      <Box mb={2} display="flex" alignItems="center">
        <IconButton onClick={toHome}>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z"
              fill="currentColor"
            />
          </svg>
        </IconButton>
        <Typography variant="h6">
          <Translate value="modulo.circulares" />
        </Typography>
      </Box>
      <Grid container spacing={3} style={{ marginBottom: 10 }}>
        <TabButtons
          tabSelected={tabSelected}
          onTabChange={handleTabChange}
          classes={classes}
        />
      </Grid>
      <Box style={{ paddingBottom: 15 }}>
        <Suspense fallback={<div>Loading...</div>}>
          {tabSelected === TAB_CIRCULARES && (
            <PageCircularesComponentes key={TAB_CIRCULARES} />
          )}
          {tabSelected === TAB_REGLAMENTOS && (
            <PageReglamentosComponente key={TAB_REGLAMENTOS} />
          )}
        </Suspense>
      </Box>
    </MainLayout>
  );
};

const TabButtons: React.FC<{
  tabSelected: number;
  onTabChange: (tab: number) => void;
  classes: any;
}> = ({ tabSelected, onTabChange, classes }) => {
  return (
    <div className={classes.box}>
      <Box>
        <button
          // type="button"
          onClick={() => {
            onTabChange(TAB_CIRCULARES);
          }}
          className={clsx(classes.boxItem, {
            [classes.boxItemSelected]: tabSelected === TAB_CIRCULARES,
          })}
        >
          <Translate value="modulo.circulares" />
        </button>
      </Box>
      <Box>
        <button
          // type="button"
          onClick={() => {
            onTabChange(TAB_REGLAMENTOS);
          }}
          className={clsx(classes.boxItem, {
            [classes.boxItemSelected]: tabSelected === TAB_REGLAMENTOS,
          })}
        >
          <Translate value="modulo.reglamentos" />
        </button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      background: '#FFFFFF',
      border: '1px solid #FAE268',
      boxSizing: 'border-box',
      borderRadius: '6px',
      marginTop: '15px',
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: '1px solid #AAAAAA',
    },
    boxItem: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      borderBottom: '1px solid #AAAAAA',
      borderLeft: '1px solid #AAAAAA',
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxItemSelected: {
      fontWeight: 'bold',
      backgroundColor: '#fff7b080',
    },
  }),
);

export default Circulares;
