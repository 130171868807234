import React, { useCallback, useEffect, useState } from 'react';
import { useStylesModalPhotoStudents } from './modal-reject';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Slider,
  Typography,
} from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import { Close as CloseIcon } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { IStudentPhoto } from '../../../models/estudiante_foto_model';
import { httpBlob } from '../../../repositories/http';
import Cropper from 'react-easy-crop';

// Utilidad para recortar la imagen
const getCroppedImg = (
  imageSrc: string,
  croppedAreaPixels: any,
): Promise<Blob> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      ctx?.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        }
      });
    };
  });
};

type ModalApproveProps = {
  student: IStudentPhoto;
  loading: boolean;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (
    action: 'reject' | 'approve',
    data: {
      idStudent: string;
      idSolicitud: string;
      croppedImage?: Blob | null;
    },
  ) => void;
};

const ModalApprove: React.FC<ModalApproveProps> = ({
  isModalOpen,
  handleClose,
  student,
  loading,
  handleSave,
}) => {
  const classes = useStylesModalPhotoStudents();
  const [imageUrl, setImageUrl] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const { handleSubmit } = useForm<{ idStudent: string }>({
    shouldUnregister: false,
    defaultValues: {
      idStudent: student.idEstudiante,
    },
    mode: 'all',
  });

  useEffect(() => {
    fetchImage(student.idEstudiante).finally();
  }, [student]);

  const fetchImage = async (idStudent: string) => {
    try {
      const response: any = await httpBlob.get(`/estudiante/${idStudent}/foto`);
      const contentType = response.headers['content-type'];
      if (!contentType || !contentType.startsWith('image/')) {
        return;
      }
      const imageUrlObject = URL.createObjectURL(response.data);
      setImageUrl(imageUrlObject);
    } catch (error) {
      console.error('Error al obtener la imagen:', error);
    }
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSubmit = async () => {
    // GENERAMOS EL CROPPED DE LA IMAGEN
    const croppedImageBlob: Blob = await getCroppedImg(
      imageUrl,
      croppedAreaPixels,
    );

    // GUARDAMOS LA IMAGEN CROPPED AND APPROVE STUDENT PHOTO
    handleSave('approve', {
      idStudent: student.idEstudiante,
      idSolicitud: student.id,
      croppedImage: croppedImageBlob,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid
          container
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            <Translate value="studentPhotos.approveTitle" />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box m={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                  {student.nombreEstudiante}
                </Typography>
              </Grid>
              {imageUrl ? (
                <Box
                  style={{
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                  }}
                >
                  <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </Box>
              ) : (
                <Box
                  style={{
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Box mt={2} width="100%">
                <Typography variant="body2">Zoom</Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(_e, zoom) => setZoom(zoom)}
                />
              </Box>
            </Grid>
          </Box>
          {loading && (
            <Box position="absolute" left={0} right={0}>
              <LinearProgress color="primary" />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            disabled={loading}
            onClick={handleClose}
            className={classes.btnError}
            fullWidth
          >
            <Translate value="form.cancelar" />
          </Button>
          <Button
            disabled={loading || !croppedAreaPixels}
            type="submit"
            className={classes.btnSave}
            fullWidth
          >
            <Typography variant="body2" noWrap>
              <Translate value="enviar" />
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalApprove;
