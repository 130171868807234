import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import { Translate } from "react-redux-i18n";
import ReporteSimple from "./components/ReporteSimple";
import clsx from "clsx";
import ReporteKinder from "./components/ReporteKinder";
import ModalSkills from "./components/ModalSkills";
import DoughnutReport from "./components/DoughnutReport";

type ReportProps = {
  loginStatus?: string;
};

const Report: React.FC<ReportProps> = ({ loginStatus }) => {
  const classes = useStyles();
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    const userLocal = window.localStorage.getItem('user');
    if (userLocal) {
      setUser(JSON.parse(userLocal))
    }
  }, []);

  useEffect(() => {
    if (user?.rol === 'PROFESOR' && user?.claims.tipoProfesor === 'KINDER')
      setReportArea('k');
    else
      setReportArea('ps');

  }, [user]);

  const [reportArea, setReportArea] = React.useState('ps');
  const [modalFormCourse, setModalFormCourse] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReportArea(event.target.value as string);
  };

  const [reporteActual, setEstadoActual] = React.useState(
    "LIBRO_CLASES_POR_SESION"
  );

  const [kinderReport, setKinderReport] = React.useState<string>('ASISTENCIA');

  const handleGetPermisos = (estado: string) => {
    setEstadoActual(estado);
  };

  const handleKinderReport = (report: string) => {
    setKinderReport(report);
  };

  const handleClickOpen = () => {
    setModalFormCourse(true);
  };

  const handleClose = () => {
    setModalFormCourse(false);
  };

  const handleSaveSkills = (data: any) => {
    console.log('qwerty')
    console.log(data)
  }

  return (
    <MainLayout>
      <Grid container spacing={3} justify="center">
        <Box my={3}>
          <InputLabel id="demo-simple-select-label">
            <Translate value="kinder.selecciona" />:
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reportArea}
            onChange={handleChange}
            style={{ width: '200px' }}
            disabled={user?.rol === 'PROFESOR' ? true : false}
          >
            <MenuItem value={'ps'}>
              <Translate value="kinder.primariaSecundaria" />
            </MenuItem>
            <MenuItem value={'k'}>
              <Translate value="kinder.kinder" />
            </MenuItem>
          </Select>
          {user?.rol === 'MONITOR' && reportArea === 'k' ?
            <Button variant="contained" style={{ backgroundColor: '#99BA82', marginLeft: '10px' }} onClick={handleClickOpen}>
              +&nbsp;<Translate value="kinder.competenciasKinder" />
            </Button>
            : null
          }
        </Box>

      </Grid>
      {
        reportArea === 'ps' ?
          <React.Fragment>
            <Grid container spacing={3}>
              <div className={classes.box}>
                {/* <Box>
                  <button
                    type="button"
                    onClick={() => handleGetPermisos("PERMISOS_POR_ESTUDIANTE")}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        reporteActual === "PERMISOS_POR_ESTUDIANTE",
                    })}
                  >
                    <Translate value="reporte.permisosPorEstudiante" />
                  </button>
                </Box> */}
                <Box>
                  {/* <button
                    type="button"
                    onClick={() => handleGetPermisos("ASISTENCIAS_POR_MATERIA")}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        reporteActual === "ASISTENCIAS_POR_MATERIA",
                    })}
                  >
                    <Translate value="reporte.asistenciasPorMateria" />
                  </button> */}
                </Box>
                <Box>
                  <button
                    type="button"
                    onClick={() => handleGetPermisos("LIBRO_CLASES_POR_SESION")}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        reporteActual === "LIBRO_CLASES_POR_SESION",
                    })}
                  >
                    <Translate value="reporte.libroClasesPorSesion" />
                  </button>
                </Box>
                <Box>
                  <button
                    type="button"
                    onClick={() => handleGetPermisos("LIBRO_CLASES_ACUMULADO")}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        reporteActual === "LIBRO_CLASES_ACUMULADO",
                    })}
                  >
                    <Translate value="reporte.libroClasesAcumulado" />
                  </button>
                </Box>
                <Box>
                  <button
                    type="button"
                    onClick={() => handleGetPermisos("REPORTE_TRIMESTRAL_ACUMULADO")}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        reporteActual === "REPORTE_TRIMESTRAL_ACUMULADO",
                    })}
                  >
                    <Translate value="reporte.trimestral" />
                  </button>
                </Box>
              </div>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <React.Fragment>
                  <Paper className={classes.root}>
                    {reporteActual === "PERMISOS_POR_ESTUDIANTE" && (
                      <ReporteSimple tipoReporte="PERMISOS_POR_ESTUDIANTE" />
                    )}
                    {reporteActual === "ASISTENCIAS_POR_MATERIA" && (
                      <ReporteSimple tipoReporte="ASISTENCIAS_POR_MATERIA" />
                    )}
                    {reporteActual === "LIBRO_CLASES_POR_SESION" && (
                      <ReporteSimple tipoReporte="LIBRO_CLASES_POR_SESION" />
                    )}
                    {reporteActual === "LIBRO_CLASES_ACUMULADO" && (
                      <ReporteSimple tipoReporte="LIBRO_CLASES_ACUMULADO" />
                    )}
                    {reporteActual === "REPORTE_TRIMESTRAL_ACUMULADO" && (
                      <ReporteSimple tipoReporte="REPORTE_TRIMESTRAL_ACUMULADO" filtroExpandido />
                    )}
                  </Paper>
                </React.Fragment>
              </Grid>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid container spacing={3}>
              <div className={classes.box}>
                {user?.rol === 'PROFESOR' || user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport("ASISTENCIA")}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "ASISTENCIA",
                      })}
                    >
                      <Translate value="reporte.kinderAsistencia" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport("PERMISOS")}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "PERMISOS",
                      })}
                    >
                      <Translate value="reporte.permisos" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport("REPORTE_LIBRO_DE_CURSO")}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "REPORTE_LIBRO_DE_CURSO",
                      })}
                    >
                      <Translate value="kinder.libroDeCursoNoCamelB" />
                    </button>
                  </Box> : null
                }
                {/* <Box>
                  <button
                    type="button"
                    onClick={() => console.log('TODO')}
                    className={clsx(classes.boxItem, {
                      [classes.boxItemSelected]:
                        kinderReport === "LIBRO_DE_CURSO",
                    })}
                  >
                    <Translate value="Libro de curso" />
                  </button>
                </Box> : null */}

                {user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport("LIBRO_DE_CURSO_NO_LLENADO")}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "LIBRO_DE_CURSO_NO_LLENADO",
                      })}
                    >
                      <Translate value="reporte.libroDeCursoNoLlenado" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'PROFESOR' || user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport('COMPETENCIAS_GENERAL')}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "COMPETENCIAS_GENERAL",
                      })}
                    >
                      <Translate value="reporte.kinderGraficoAreasDesarrollo" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'PROFESOR' || user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport('COMPETENCIAS_POR_AREAS')}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "COMPETENCIAS_POR_AREAS",
                      })}
                    >
                      <Translate value="reporte.kinderGraficoAreas" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'PROFESOR' || user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport('ALERGIAS')}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "ALERGIAS",
                      })}
                    >
                      <Translate value="reporte.alergias" />
                    </button>
                  </Box> : null
                }
                {user?.rol === 'PROFESOR' || user?.rol === 'MONITOR' ?
                  <Box>
                    <button
                      type="button"
                      onClick={() => handleKinderReport('ACCIDENTES')}
                      className={clsx(classes.boxItem, {
                        [classes.boxItemSelected]:
                          kinderReport === "ACCIDENTES",
                      })}
                    >
                      <Translate value="reporte.accidentes" />
                    </button>
                  </Box> : null
                }
              </div>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <React.Fragment>
                  <Paper className={classes.root}>
                    {kinderReport === "PERMISOS" && (
                      <ReporteKinder tipoReporte="PERMISOS" />
                    )}
                    {kinderReport === "ASISTENCIA" && (
                      <ReporteKinder tipoReporte="ASISTENCIA" />
                    )}
                    {kinderReport === "LIBRO_DE_CURSO" && (
                      <ReporteKinder tipoReporte="LIBRO_CLASES_POR_SESION" />
                    )}
                    {kinderReport === "LIBRO_DE_CURSO_NO_LLENADO" && (
                      <ReporteKinder tipoReporte="LIBRO_CURSO_NO_LLENADO" />
                    )}
                    {kinderReport === "REPORTE_LIBRO_DE_CURSO" && (
                      <ReporteKinder tipoReporte="REPORTE_LIBRO_DE_CURSO" />
                    )}
                    {kinderReport === "COMPETENCIAS_GENERAL" && (
                      <div>
                        <DoughnutReport tipoReporte="COMPETENCIAS_GENERAL" />
                      </div>
                    )}
                    {kinderReport === "COMPETENCIAS_POR_AREAS" && (
                      <div>
                        <DoughnutReport tipoReporte="COMPETENCIAS_POR_AREAS" />
                      </div>
                    )}
                    {kinderReport === "ALERGIAS" && (
                      <ReporteKinder tipoReporte="ALERGIAS" />
                    )}
                    {kinderReport === "ACCIDENTES" && (
                      <ReporteKinder tipoReporte="ACCIDENTES" />
                    )}
                  </Paper>
                </React.Fragment>
              </Grid>
            </Grid>
          </React.Fragment>
      }
      <ModalSkills
        isModalOpen={modalFormCourse}
        handleClose={handleClose}
        handleSaveSkills={handleSaveSkills}
      />
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    box: {
      background: "#FFFFFF",
      border: "1px solid #FAE268",
      boxSizing: "border-box",
      borderRadius: "6px",
      marginTop: "15px",
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: "1px solid #AAAAAA",
    },
    boxItem: {
      padding: "8px 16px",
      background: "transparent",
      border: "none",
      borderBottom: "1px solid #AAAAAA",
      borderLeft: "1px solid #AAAAAA",
      width: "220px",
      height: '50px',
      textAlign: "center",
      cursor: "pointer"
    },
    boxItemSelected: {
      fontWeight: "bold",
      backgroundColor: "#fff7b080",
    },
    boxTitle: {
      fontSize: "1rem",
      margin: "10px 0",
    },
  })
);

export default Report;
