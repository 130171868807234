import {
  Box,
  Button,
  Typography,
  makeStyles,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { getEstudiantesParaAsistencia, guardarAsistencia } from 'repositories/estudiante_repository';
import message from 'plugins/utils/message';
import { I18n, Translate } from 'react-redux-i18n';

type TableModel = {
  asistenciaId: string;
  estudianteId: string;
  estudianteNombre: string;
  estudianteFoto: string;
  valor: string;
  tienePermiso: boolean;
  permisoParcial?: boolean;
};

export type EstudianteAsistencia = {
  estudiante: {
    id: string;
    nombre: string;
    foto: string;
  };
  asistencia: {
    id: string;
    valor: string;
  } | null;
  permiso: {
    id: string;
    fechaInicio: string;
    fechaFin: string;
    motivo: string;
    permisoParcial: boolean;
  } | null;
};

async function getEstudiantes(cursoId: string, claseId: string) {
  const estudiantes: EstudianteAsistencia[] = await getEstudiantesParaAsistencia(cursoId, claseId);
  if (!estudiantes) return { rows: [] };

  const rows: TableModel[] = estudiantes.map(est => {
    return {
      estudianteId: est.estudiante.id,
      estudianteNombre: est.estudiante.nombre,
      estudianteFoto: est.estudiante.foto,
      asistenciaId: est.asistencia?.id || '',
      valor: est.asistencia?.valor || '',
      tienePermiso: !!est.permiso,
      permisoParcial: est.permiso?.permisoParcial,
    };
  });

  return { rows };
}

type StepAsistenciaProps = {
  cursoId: string;
  claseId: string;
  materiaId: string;
  onGuardarClick: () => void;
};

const StepAsistencia = ({ cursoId, claseId, materiaId, onGuardarClick }: StepAsistenciaProps): ReactElement => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableModel[]>([]);
  const [presenteChecks, setPresenteChecks] = React.useState<{ [key: string]: boolean }>({});
  const [retrasoChecks, setRetrasoChecks] = React.useState<{ [key: string]: boolean }>({});
  const [faltaChecks, setFaltaChecks] = React.useState<{ [key: string]: boolean }>({});
  const isMounted = useIsMounted();

  const [studentPermissionsToDeleteIds, setStudentPermissionsToDeleteIds] = React.useState<string[]>([]);

  const cargarEstudiantes = async () => {
    setLoading(true);
    const result = await getEstudiantes(cursoId, claseId);
    if (isMounted()) setLoading(false);
    if (!result) return [];
    setRows(result.rows);
    const newPresenteChecks: any = {};
    const newRetrasoChecks: any = {};
    const newFaltaChecks: any = {};
    result.rows.forEach(r => {
      newPresenteChecks[r.estudianteId] = true;
      newRetrasoChecks[r.estudianteId] = false;
      newFaltaChecks[r.estudianteId] = false;

      if (r.valor === 'ASISTIO') {
        newPresenteChecks[r.estudianteId] = true;
        newRetrasoChecks[r.estudianteId] = false;
        newFaltaChecks[r.estudianteId] = false;
      }

      if (r.valor === 'RETRASO') {
        newPresenteChecks[r.estudianteId] = true;
        newRetrasoChecks[r.estudianteId] = true;
        newFaltaChecks[r.estudianteId] = false;
      }

      if (r.valor === 'FALTA') {
        newPresenteChecks[r.estudianteId] = false;
        newRetrasoChecks[r.estudianteId] = false;
        newFaltaChecks[r.estudianteId] = true;
      }

      if (r.tienePermiso && !r.permisoParcial) {
        newPresenteChecks[r.estudianteId] = true;
        newRetrasoChecks[r.estudianteId] = false;
        newFaltaChecks[r.estudianteId] = false;
      }
    });
    setPresenteChecks(newPresenteChecks);
    setRetrasoChecks(newRetrasoChecks);
    setFaltaChecks(newFaltaChecks);
  };
  const cargadoInicial = async () => {
    cargarEstudiantes();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleGuardarClick = async () => {
    const asistencias = rows.map(row => {
      const retraso = !!retrasoChecks[row.estudianteId];
      const falta = !!faltaChecks[row.estudianteId];
      let valor = 'ASISTIO';
      if (retraso) valor = 'RETRASO';
      if (falta) valor = 'FALTA';
      const eliminarPermiso = studentPermissionsToDeleteIds.includes(row.estudianteId) || undefined;
      return {
        estudianteId: row.estudianteId,
        valor,
        eliminarPermiso,
      };
    });
    const data: any = {
      fecha: '2000-01-01',
      claseId,
      materiaId,
      asistencias,
    };
    await guardarAsistencia(data);
    message.success(I18n.t('asistenciasGuardadasConExito'));
    onGuardarClick();
  };

  const deletePermiso = async (estudianteId: string) => {
    const deletePermiso = await message.confirm(I18n.t('eliminarPermiso'));
    if (deletePermiso) {
      setRows(rows.map(r => {
        if (r.estudianteId === estudianteId) {
          r.tienePermiso = false;
          r.permisoParcial = false;
        }
        return r;
      }));
      setStudentPermissionsToDeleteIds(Array.from(new Set([...studentPermissionsToDeleteIds, estudianteId])));
      return true;
    }
    return false;
  };

  const handlePresenteChange = async (row: TableModel, checked: boolean) => {
    if (row.tienePermiso || row.permisoParcial) {
      const respuesta = await deletePermiso(row.estudianteId);
      if (!respuesta) return;
    }
    setPresenteChecks({ ...presenteChecks, [row.estudianteId]: true });
    setFaltaChecks({ ...faltaChecks, [row.estudianteId]: false });
  };

  const handleRetrasoChange = async (row: TableModel, checked: boolean) => {
    if (row.tienePermiso || row.permisoParcial) {
      const respuesta = await deletePermiso(row.estudianteId);
      if (!respuesta) return;
    }
    if (checked) {
      setPresenteChecks({ ...presenteChecks, [row.estudianteId]: true });
      setRetrasoChecks({ ...retrasoChecks, [row.estudianteId]: true });
      setFaltaChecks({ ...faltaChecks, [row.estudianteId]: false });
      return;
    }

    if (!checked) {
      setPresenteChecks({ ...presenteChecks, [row.estudianteId]: true });
      setRetrasoChecks({ ...retrasoChecks, [row.estudianteId]: false });
      setFaltaChecks({ ...faltaChecks, [row.estudianteId]: false });
      return;
    }
  };

  const handleFaltaChange = async (row: TableModel, checked: boolean) => {
    if (row.tienePermiso || row.permisoParcial) {
      const respuesta = await deletePermiso(row.estudianteId);
      if (!respuesta) return;
    }
    setPresenteChecks({ ...presenteChecks, [row.estudianteId]: false });
    setRetrasoChecks({ ...retrasoChecks, [row.estudianteId]: false });
    setFaltaChecks({ ...faltaChecks, [row.estudianteId]: true });
  };

  return (
    <>
      {loading && <Box position="absolute" left="0" right="0">
        <LinearProgress variant="indeterminate" color="secondary" />
      </Box>}
      <Box className={classes.root}>
        <Box position="relative">
          <TableContainer component={Paper} elevation={0} style={{ border: 'solid lightgrey 1px' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong><Translate value="estudiante" /></strong></TableCell>
                  <TableCell></TableCell>
                  <TableCell><strong><Translate value="asistenciaPresente" /></strong></TableCell>
                  <TableCell><strong><Translate value="asistenciaRetraso" /></strong></TableCell>
                  <TableCell><strong><Translate value="asistenciaFaltaSinLicencia" /></strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && <TableRow><TableCell colSpan={999}><Typography
                  align="center">Cargando...</Typography></TableCell></TableRow>}
                {!loading && rows.length === 0 && <TableRow><TableCell colSpan={999}><Typography align="center">Sin
                  registros</Typography></TableCell></TableRow>}
                {rows.map((row) => (
                  <TableRow key={row.estudianteId}>
                    <TableCell>{row.estudianteNombre}</TableCell>
                    <TableCell>
                      {row.tienePermiso && (
                        <Button title={'Eliminar el permiso del estudiante'} style={{
                          color: '#99BA82',
                          textTransform: 'none',
                        }} onClick={() => deletePermiso(row.estudianteId)}
                        >
                          {row.permisoParcial ? <Translate value="tienePermisoParcial" /> :
                            <Translate value="tienePermiso" />}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      {typeof presenteChecks[row.estudianteId] !== 'undefined' && <Checkbox
                        // style={{ color: row.tienePermiso && !row.permisoParcial ? undefined : '#99BA82' }}
                        style={{ color: '#99BA82' }}
                        checked={presenteChecks[row.estudianteId]}
                        // disabled={row.tienePermiso && !row.permisoParcial}
                        onChange={(event) => handlePresenteChange(row, event.target.checked)}
                      />}
                    </TableCell>
                    <TableCell>
                      {typeof retrasoChecks[row.estudianteId] !== 'undefined' && <Checkbox
                        // style={{ color: row.tienePermiso && !row.permisoParcial ? undefined : '#EBAB88' }}
                        style={{ color: '#EBAB88' }}
                        checked={retrasoChecks[row.estudianteId]}
                        // disabled={row.tienePermiso && !row.permisoParcial}
                        onChange={(event) => handleRetrasoChange(row, event.target.checked)}
                      />}
                    </TableCell>
                    <TableCell>
                      {typeof faltaChecks[row.estudianteId] !== 'undefined' && <Checkbox
                        // style={{ color: row.tienePermiso && !row.permisoParcial ? undefined : '#F0968A' }}
                        style={{ color: '#F0968A' }}
                        checked={faltaChecks[row.estudianteId]}
                        // disabled={row.tienePermiso && !row.permisoParcial}
                        onChange={(event) => handleFaltaChange(row, event.target.checked)}
                      />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" alignContent="center" justifyContent="center">
          <Button type="button" onClick={handleGuardarClick} className={classes.btnSaveForm} fullWidth>
            <Typography variant="body2" noWrap><Translate value="form.guardar" /></Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
  btnSaveForm: {
    margin: theme.spacing(4, 'auto'),
    maxWidth: '150px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  table: {
    minWidth: 650,
  },
}));

export default StepAsistencia;
