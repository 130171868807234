import { IStudentPhoto } from '../../models/estudiante_foto_model';
import { STUDENTS_PHOTOS_TYPES } from '../types';

export type State = {
  studentPhotos: IStudentPhoto[] | null;
};

export const initialState: State = {
  studentPhotos: null,
};

const reducer = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case STUDENTS_PHOTOS_TYPES.LOAD_PHOTOS:
      return {
        ...state,
        studentPhotos: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
