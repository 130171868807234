import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  FormControl,
  Button,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import {
  Refresh as RefreshIcon,
  GridOn as GridOnIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIsMounted } from 'hooks/useIsMounted';
import { getReporte } from 'repositories/reporte_repository';
import { obtenerParametricas } from '../../../repositories/parametrica_repository';
import { descargarReporteExcel } from '../../../store/actions/reporte_actions';
import { I18n, Translate } from 'react-redux-i18n';
import { parse as dateParse } from 'date-fns';

const FORMAT = 'dd/MM/yyyy';
const DEFAULT_ROWS_PER_PAGE = 50;
const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

export type AnotacionesReporteSimpleRefProps = {
  refresh: () => void;
};

type Props = {
  estudiante?: string | null;
};

type ParametricaOption = {
  id: string;
  codigo: string;
  grupo: string;
  nombre: string;
  descripcion: string;
};

type SelectOption = {
  value: string;
  label: string;
  caption?: string;
};

type ReporteModel = {
  rows: ReporteRow[];
  columns: ReporteColumn[];
  count: number;
};

type ReporteRow = {
  [key: string]: ReporteItem;
};

type ReporteColumn = {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  minWidth?: number;
  hidden?: boolean;
};

type ReporteItem = string | number | boolean | null;

const AnotacionesReporteSimple = forwardRef<
  AnotacionesReporteSimpleRefProps,
  Props
>(({ estudiante }, ref) => {
  const tipoReporte = 'KARDEX_ANOTACIONES';
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const [stateFilters, setStateFilters] = useState<any>({});
  const [desde, setDesde] = useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = useState<string | null>(DEFAULT_HASTA);
  const [category, setCategory] = useState<string | null>(null);
  const [rows, setRows] = useState<ReporteRow[]>([]);
  const [columns, setColumns] = useState<ReporteColumn[]>([]);
  const [count, setCount] = useState<number>(0);
  const [parametricasCategoriasOptions, setParametricasCategoriasOptions] =
    useState<SelectOption[]>([]);

  const cargarReporte = useCallback(
    async (filters: any, page: number, rowsPerPage: number) => {
      setLoading(true);
      filters.estudiante = estudiante;
      const query = { _limit: rowsPerPage, _page: page + 1 };
      const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (category) filters.category = category;

      try {
        const result = (await getReporte(
          tipoReporte,
          fechaDesde,
          fechaHasta,
          filters,
          query,
        )) as ReporteModel;
        if (isMounted()) {
          setRows(result.rows);
          setColumns(result.columns);
          setCount(result.count);
        }
      } catch (error) {
        console.error('Error cargando reporte:', error);
      } finally {
        if (isMounted()) setLoading(false);
      }
    },
    [estudiante, desde, hasta, category, isMounted],
  );

  const cargadoInicial = useCallback(async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    await cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  }, [cargarReporte]);

  useEffect(() => {
    cargadoInicial();
  }, [cargadoInicial]);

  const handleRefreshReporte = () => {
    setPage(0);
    cargarReporte(stateFilters, 0, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    cargarReporte(stateFilters, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    cargarReporte(stateFilters, 0, newRowsPerPage);
  };

  const textoTraducido: any = useMemo(
    () => ({
      Otros: I18n.t('categoriaAnotacion.otro'),
      Aplicación: I18n.t('categoriaAnotacion.aplicacion'),
      'Sin Tarea': I18n.t('categoriaAnotacion.sinTarea'),
      Conducta: I18n.t('categoriaAnotacion.conducta'),
      Orden: I18n.t('categoriaAnotacion.orden'),
      Atención: I18n.t('categoriaAnotacion.atencion'),
    }),
    [],
  );

  const renderText = useCallback(
    (value: ReporteItem) => {
      if (typeof value !== 'string') return null;
      return value
        .split('|')
        .map((line, lineIndex) => (
          <Typography key={lineIndex}>
            {textoTraducido[line] || line}
          </Typography>
        ));
    },
    [textoTraducido],
  );

  const handleOnChange = useCallback(
    async (value: any) => {
      if (loading) return;
      setCategory(value);
      setLoading(true);
      if (!isMounted()) return;
      setLoading(false);
    },
    [loading, isMounted],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: SelectOption | null) => {
      const newValue = value?.value || '';
      handleOnChange(newValue);
    },
    [handleOnChange],
  );

  const cargarOpciones = useCallback(async () => {
    const parametricasResponse = await obtenerParametricas(
      'CATEGORIA_ANOTACION',
    );
    const listado = parametricasResponse as ParametricaOption[];
    const estudianteOptions: SelectOption[] = listado.map((item) => ({
      value: item.codigo || '',
      label: `${item.nombre}`,
    }));
    setParametricasCategoriasOptions(estudianteOptions);
  }, []);

  useEffect(() => {
    cargarOpciones();
  }, [cargarOpciones]);

  const handleClickDescargarExcel = useCallback(async () => {
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const stateFilters = {
      estudiante: estudiante || undefined,
      category: category || undefined,
    };
    dispatch(
      descargarReporteExcel(tipoReporte, fechaDesde, fechaHasta, stateFilters),
    );
  }, [desde, hasta, estudiante, category, dispatch]);

  useImperativeHandle(
    ref,
    () => ({
      refresh: cargadoInicial,
    }),
    [cargadoInicial],
  );

  return (
    <Paper className={classes.root}>
      {loading && (
        <Box position="absolute" left={0} right={0}>
          <LinearProgress color="secondary" />
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '10px',
          gap: '10px',
        }}
      >
        <Button
          variant="contained"
          onClick={handleRefreshReporte}
          startIcon={<RefreshIcon />}
        >
          {isDesktop && <Translate value="tabla.refrescar" />}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickDescargarExcel}
          startIcon={<GridOnIcon />}
        >
          <Translate value="tabla.generarExcel" />
        </Button>
      </Box>
      <Grid container spacing={2} style={{ padding: '10px' }}>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} variant="standard">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <KeyboardDatePicker
                variant="inline"
                className={classes.input}
                id="filtro-desde"
                label={<Translate value="tabla.desde" />}
                autoOk
                format="dd/MM/yyyy"
                value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                inputVariant="standard"
                onChange={(date, value) => setDesde(value || null)}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <KeyboardDatePicker
                variant="inline"
                className={classes.input}
                id="filtro-hasta"
                label={<Translate value="tabla.hasta" />}
                autoOk
                format="dd/MM/yyyy"
                value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                onChange={(date, value) => setHasta(value || null)}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={5} style={{ maxHeight: '8px' }}>
          <Autocomplete
            size="small"
            renderOption={(option) => (
              <Box>
                <Typography variant="body2">{option.label}</Typography>
                {option.caption && (
                  <Typography variant="caption">{option.caption}</Typography>
                )}
              </Box>
            )}
            options={parametricasCategoriasOptions}
            getOptionLabel={(option: SelectOption) =>
              option.caption
                ? `${option.label} - ${option.caption}`
                : `${option.label}`
            }
            getOptionSelected={(option, value) => value.value === option.value}
            value={
              parametricasCategoriasOptions.find(
                (opt) => opt.value === category,
              ) || null
            }
            disabled={loading}
            noOptionsText={` >>> ${I18n.t('reporte.sinOpciones')} <<<`}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                name="buscador"
                required
                label={
                  loading
                    ? `${I18n.t('reporte.cargando')}...`
                    : `${I18n.t('categoria')}`
                }
                variant="filled"
              />
            )}
          />
        </Grid>
      </Grid>
      {rows.length === 0 && (
        <Typography variant="subtitle1" align="center">
          <Translate value="tabla.sinRegistrosReporte" />
        </Typography>
      )}
      {rows.length > 0 && (
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="nro"
                    align="left"
                    style={{ minWidth: 60, fontWeight: 'bold' }}
                  >
                    <Translate value="tabla.nro" />
                  </TableCell>
                  {columns
                    .filter((c) => !c.hidden)
                    .map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: 'bold',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: ReporteRow, rowIndex: number) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    <TableCell key="nro">
                      <Typography>
                        {rowIndex + 1 + page * rowsPerPage}
                      </Typography>
                    </TableCell>
                    {columns
                      .filter((c) => !c.hidden)
                      .map((column: ReporteColumn, colIndex: number) => (
                        <TableCell key={colIndex}>
                          <div>{renderText(row[colIndex])}</div>
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 50, 100, 250]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${I18n.t('tabla.de')} ${
                count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`
              }`
            }
          />
        </>
      )}
    </Paper>
  );
});

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    minWidth: '600px',
  },
  container: {},
  formControl: {
    width: '160px',
    padding: '0 0 0 20px',
  },
  input: {
    margin: 0,
  },
  resaltado: {
    backgroundColor: '#ffcccc',
  },
});

export default AnotacionesReporteSimple;
