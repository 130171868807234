// Para la autenticación con Firebase
// 1ro - Asegurarse de crear un Realtime Database desde:
// Consola de Firebase > Realtime Database > Crear una base de datos (https://console.firebase.google.com/project)
// 2do - Obtener los parámetros de configuración desde:
// Consola de Firebase > Configuración > General > Agregar una app (Aplicación web) (https://console.firebase.google.com/project)
export const firebaseConfig = {
  apiKey: "AIzaSyCWHQuKbZkj_KXljfCvGPovntc9FivbbMU",
  authDomain: "kinder-aleman-fabho.firebaseapp.com",
  databaseURL: "https://kinder-aleman-fabho-default-rtdb.firebaseio.com",
  projectId: "kinder-aleman-fabho",
  storageBucket: "kinder-aleman-fabho.appspot.com",
  messagingSenderId: "914166676607",
  appId: "1:914166676607:web:8ee259444cf54975a7534a",
  measurementId: "G-V90VKQXXY5",
};

// Para el envío de notificaciones push
// Consola de Firebase > Configuración > Cloud Messaging > Certificados push web > Generate key pair (https://console.firebase.google.com/project)
export const PUBLIC_VAPID_KEY = 'BLsKpdHsZu-O4TOJWMq-N-kLp9FDhK_e3kZnT7O0WqMfQW_Te_QRvDMxRqj84hM73D43inPwkkrWRcHTJsAuwZI';

// Para acceder a los archivos almacenados en Google Cloud Storage (la misma que se creó para el server)
// Consola de Firebase > Storage > Comenzar (https://console.firebase.google.com/project)
//      - Paso 1 Utilizar las reglas de seguridad por defecto
//      - Paso 2 Seleccionar la ubicación geográfica deonde se almacenarán los datos
//
//      gs://escuelas-manqa-localhost.appspot.com
//
// NOTA.- Al hacer esto se creará un bucket (escuelas-manqa-localhost.appspot.com)
//        en Google Cloud Storage (https://console.cloud.google.com/storage/browser)
//
// URL pública = https://storage.googleapis.com
// URL privada = https://storage.cloud.google.com
export const BUCKET_NAME = 'kinder-aleman-fabho.appspot.com';
export const STORAGE_URL = `https://storage.googleapis.com/${BUCKET_NAME}`;
