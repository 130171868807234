import { REGLAMENTO_TYPES } from 'store/types';
import { IReglamento } from '../../models/reglamento_model';

export type State = {
  reglamentos: IReglamento[] | [];
};

const initialState: State = {
  reglamentos: [],
};

const reducer = (state: State = initialState, { type, payload }: any) => {
  switch (type) {
    case REGLAMENTO_TYPES.LOAD_REGLAMENTOS:
      return {
        ...state,
        reglamentos: payload,
      };
    default:
      return state;
  }
};

export default reducer;
