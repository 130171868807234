import swal from "sweetalert";
import axios, { AxiosRequestConfig, Method } from "axios";
import { firebaseAuth, firebaseStorage } from "plugins/firebase";
const urlBase = process.env.REACT_APP_API_URL || "/api/admin";

const getHeaders = async (baseHeaders?: any): Promise<any> => {
  const DEFAULT_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const HEADERS = baseHeaders ? baseHeaders : DEFAULT_HEADERS;
  return new Promise((resolve) => {
    firebaseAuth.onAuthStateChanged((authUser) => {
      if (authUser) {
        return authUser
          .getIdToken()
          .then((idToken) => {
            HEADERS["Authorization"] = `Bearer ${idToken}`;
            resolve(HEADERS);
          })
          .catch(() => resolve(HEADERS));
      }
      return resolve(HEADERS);
    });
  });
};

const _http = async (
  method: Method,
  url: string,
  data?: any,
  headers?: any
) => {
  const newHeaders = await getHeaders(headers);
  return new Promise((resolve, reject) => {
    url = urlBase + url;
    const config: AxiosRequestConfig = {
      method,
      url,
      data,
      headers: newHeaders,
    };

    axios(config)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        if (error.response?.data?.error) {
          swal({
            title: "¡Error!",
            text: error.response?.data?.error,
            icon: "error",
            buttons: {
              confirm: {
                text: "Aceptar",
              },
            },
          });
        }
        reject(error.response?.data);
      });
  });
};

const _httpBlob = async (
  method: Method,
  url: string,
  data?: any,
  headers?: any,
  responseType: 'json' | 'blob' = 'blob'
) => {
  const newHeaders = await getHeaders(headers);
  return new Promise((resolve, reject) => {
    url = urlBase + url;
    const config: AxiosRequestConfig = {
      method,
      url,
      data,
      headers: newHeaders,
      responseType,
    };

    axios(config)
      .then((response: any) => {
        if (responseType === 'blob') {
          resolve({
            data: response.data,
            headers: response.headers,
          });
        } else {
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        if (error.response?.data?.error) {
          swal({
            title: "¡Error!",
            text: error.response?.data?.error,
            icon: "error",
            buttons: {
              confirm: {
                text: "Aceptar",
              },
            },
          });
        }
        reject(error.response?.data);
      });
  });
};

async function _download(
  method: Method,
  routePath: string,
  data?: unknown,
  filename?: string,
  fileType?: 'EXCEL' | 'PDF', // Agregar mas tipos de archivos de ser necesario
): Promise<unknown> {
  try {
    const url = `${urlBase}${routePath}`;
    const baseHeaders =
      fileType === 'EXCEL'
        ? {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-Type': 'application/json',
          }
        : fileType === 'PDF'
        ? {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
          }
        : undefined;
    const response = await fetch(url, {
      method: method.toUpperCase(),
      headers: await getHeaders(baseHeaders),
      body: data ? JSON.stringify(data) : undefined,
    });
    const resObj = {
      filename:
        filename ||
        fnGetFileNameFromContentDispostionHeader(
          String(response.headers.get('content-disposition')),
        ),
      blob: await response.blob(),
    };
    const newBlob = new Blob([resObj.blob], {
      type: `${response.headers.get('content-type')}`,
    });

    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    const navigator: any = window.navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(newBlob);
      return true;
    }

    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = objUrl;
    link.download = resObj.filename;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);

    return true;
  } catch (err: any) {
    return false;
  }
}

async function _uploadFileToGoogleCloudStorage(file: File, fileName: string, onProgress?: (progress: number, uploaded: boolean) => void): Promise<any> {
  const FILE = file;
  const FILENAME = fileName;
  return new Promise((resolve) => {
      const formdata = new FormData();
      formdata.append('fileItem', FILE, FILENAME);
      formdata.append('fileName', FILENAME);
      const storageRef = firebaseStorage.ref();
      const uploadTask = storageRef.child(FILENAME).put(FILE);
      uploadTask.on(
          'state_changed',
          function (snapshot) {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (onProgress) onProgress(progress, false);
          },
          function (error) {
              console.error('[firebase] uploadTask ERROR', error);
              return resolve({ error: error.toString() });
          },
          function () {
              _http('post', '/storage/register', { fileName: FILENAME })
                .then((registerResult) => {
                  if (onProgress) onProgress(100, true);
                  return resolve(registerResult);
                })
                .catch(error => {
                  return resolve({ error: error.toString() });
                });
          },
      );
  });
}

function _removeFileFromGoogleCloudStorage(fileName: string): Promise<any> {
  return _http('delete', '/storage/upload', { fileName })
}

function fnGetFileNameFromContentDispostionHeader(header: string) {
  const contentDispostion = header ? header.split(';') : [];
  const fileNameToken = `filename=`;
  let filename = 'sample.txt';
  for (const thisValue of contentDispostion) {
      if (thisValue.trim().indexOf(fileNameToken) === 0) {
          filename = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
          break;
      }
  }
  return filename;
}

const http = {
  get(url: string, headers?: any) {
    return _http("get", url, null, headers);
  },

  post(url: string, data: any, headers?: any) {
    return _http("post", url, data, headers);
  },

  put(url: string, data: any, headers?: any) {
    return _http("put", url, data, headers);
  },

  patch(url: string, data: any) {
    return _http("patch", url, data);
  },

  delete(url: string, data?: any) {
    return _http("delete", url, data);
  },

  download(url: string, filename: string, filetype?: "EXCEL") {
    return _download('get', url, null, filename, filetype);
  },
  uploadFile(file: File, fileName: string, onProgress?: (progress: number, uploaded: boolean) => void) {
    return _uploadFileToGoogleCloudStorage(file, fileName, onProgress);
  },
  removeFile(fileName: string) {
    return _removeFileFromGoogleCloudStorage(fileName);
  }
};

export const httpBlob = {
  get(url: string, headers?: any) {
    return _httpBlob('get', url, null, headers);
  }
}

export default http;
